//todo real images for the carousel

export const imagesPaths = [
  {
    imageFolderUrl: '/assets/projects/project-1',
  },
  {
    imageFolderUrl: '/assets/projects/project-2',
  },
  {
    imageFolderUrl: '/assets/projects/project-3',
  },
];
