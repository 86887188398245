export const sectionHeight = {
  carouselItem: [6, 6, 6, 4, 4, 4, 4, 4, 4],
  carouselItemSharp: [4, 5, 6, 4, 5, 4, 4, 4, 4],
  carouselItemMobile: [2, 2, 2, 2, 0, 0, 0, 0, 0],
  carouselItemMobileSharp: [4, 3, 3, 3, 0, 0, 0, 0, 0],
  stepSlider: [7, 6, 6, 5, 5, 4, 5, 4, 5],
};

export const carouselItemLayout = {
  top: [5, 6, 6, 4, 2, 2, 2, 2, 2],
  left: [1, 1, 1, 2, 2, 2, 2, 3, 4],
  boxWidth: [3, 3, 4, 3, 3, 3, 3, 3, 3],
  boxHeight: [1, 1, 1, 1, 2, 2, 2, 2, 2],
};

export const carouselItemLayoutSharp = {
  top: [5, 6, 6, 4, 3, 2, 2, 2, 2],
  left: [1, 1, 1, 2, 2, 2, 3, 4, 5],
  boxWidth: [3, 3, 4, 3, 4, 3, 3, 3, 3],
  boxHeight: [1, 1, 1, 1, 2, 2, 2, 2, 2],
};

export const carouselTextMobileLayout = {
  top: [1, 1, 1, 1, 2, 2, 2, 1, 1],
  left: [1, 1, 1, 2, 2, 2, 2, 2, 2],
  boxWidth: [3, 3, 4, 4, 3, 3, 3, 3, 3],
  boxHeight: [1, 1, 1, 1, 2, 2, 2, 2, 2],
};

export const stepsLayoutImages = {
  horizontal: {
    top: [2, 2, 2, 2, 2, 2, 2, 2, 3],
    left: [1, 1, 1, 1, 1, 1, 2, 2, 3],
    boxWidth: [3, 2, 4, 4, 4, 4, 4, 4, 4],
    boxHeight: [1, 1, 2, 2, 2, 2, 2, 2, 2],
  },
};

export const stepHeader = {
  top: [1, 1, 1, 1, 1, 1, 1, 1, 2],
  left: [1, 1, 1, 2, 2, 2, 3, 3, 4],
  boxWidth: [3, 3, 4, 3, 2, 3, 3, 3, 3],
  boxHeight: [1, 1, 1, 2, 2, 2, 2, 2, 2],
};

export const stepText = {
  top: [2, 2, 2, 2, 1, 1, 1, 1, 2],
  left: [1, 1, 1, 2, 5, 5, 6, 7, 8],
  boxWidth: [3, 3, 4, 4, 4, 3, 3, 3, 3],
  boxHeight: [1, 1, 1, 2, 2, 2, 2, 2, 2],
};

export const sliderButton = {
  top: [5, 5, 4, 4, 3, 3, 3, 3, 4],
  left: [1, 1, 1, 2, 5, 5, 6, 7, 8],
  boxWidth: [3, 3, 4, 4, 4, 3, 3, 3, 3],
  boxHeight: [1, 1, 1, 2, 2, 2, 2, 2, 2],
};
