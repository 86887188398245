import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import {
  Gallery,
  Header,
  Teaser,
  RandomProfile,
  Image,
} from '../../components/index';
import { GridSection, GridBox, FullWidthElement } from '../../components/Grid';
import {
  sectionHeight,
  intro,
  patternsLayout,
  boltLayout,
  heartLayout,
  teaser,
  team,
  gallery,
  jobs,
} from './layouts';
import { Heading1, Text } from '../../helper/Typography';
import Sprite from '../../components/Sprite/Sprite';
import { SimpleImage } from '../../helper/StyledHelper';
import { eyeStates, boltStates, heartStates } from './animations';
import ImageCarousel from '../../components/ImageCarousel/ImageCarousel';
import { PageLayout } from '../../components/PageLayout';
import PageEnhancer, { EntryPageProps } from '../../components/PageEnhancer';
import { getUrlFromId, mapMetaObject } from '../../helper/helperServices';
import { SeoDataComponent } from '@lws/react-components';

class Home extends PageEnhancer {
  constructor(props: EntryPageProps) {
    super(props);
    this.state = {
      eye: undefined,
      heart: undefined,
      bolt: undefined,
      isScreenDesktop: true,
      darkTheme: false,
    };
  }

  componentDidMount() {
    this.callSuperComponentDidMountMethods();
    this.setInitialAnimationsState(['eye']);
  }

  componentWillUpdate(
    nextProps: { [x: string]: any },
    nextState: { [x: string]: any }
  ) {
    if (nextState.isScreenDesktop !== this.state.isScreenDesktop) {
      this.setInitialAnimationsState(['eye']);
    }
  }

  render() {
    const { generalTexts, routeTexts: home, locale } = this.props.pageContext;
    const { pathname } = this.props.location;
    const { isScreenDesktop } = this.state;
    return (
      <PageLayout
        userLanguage={locale}
        generalText={generalTexts}
        darkTheme={this.state.darkTheme}
      >
        <main className={this.state.darkTheme ? 'App Dark' : 'App Light'}>
          <Header
            pathname={pathname}
            darkTheme={this.state.darkTheme}
            generalTexts={generalTexts}
            lang={locale}
          />
          <SeoDataComponent data={mapMetaObject(home.meta).data} />
          <GridSection gridRows={sectionHeight.intro} dark={false}>
            <GridBox layout={intro.title} style={{ alignContent: 'center' }}>
              <Heading1>{home.intro.title}</Heading1>
              <Text.p>{home.intro.subtitle}</Text.p>
            </GridBox>
            {typeof this.state.eye !== 'undefined' && (
              <Sprite
                id={'eyeStart'}
                layout={intro.eye}
                name={'eye'}
                startId={'eyeStart'}
                endId={'eyeEnd'}
                animationState={this.state.eye}
                setAnimationState={this.setAnimationState}
                isSticky={isScreenDesktop}
                isActive={true}
                originalAnimState={0}
                onRelease={() => {
                  this.state.eye !== 3 && this.setAnimationState('eye', 3);
                }}
                onScroll={() => {
                  this.state.eye !== 2 && this.setAnimationState('eye', 2);
                }}
                animationsDefinitions={eyeStates}
              />
            )}
            <GridBox layout={patternsLayout.p1}>
              <SimpleImage
                src={'/assets/patterns/home/stage/pattern-v1-01.svg'}
              />
            </GridBox>
            {typeof this.state.eye !== 'undefined' && (
              <GridBox
                className={'showAbove-mobile3'}
                layout={patternsLayout.p2}
                style={{ width: '157%' }}
              >
                <SimpleImage
                  src={'/assets/patterns/home/stage/pattern-v1-02.svg'}
                />
              </GridBox>
            )}
            <GridBox id={'eyeEnd'} layout={intro.eyeEnd} />
          </GridSection>
          <GridSection gridRows={sectionHeight.video}>
            <FullWidthElement>
              <Image imageFolderUrl={'/assets/pages/home/stage'} />
            </FullWidthElement>
          </GridSection>
          <GridSection gridRows={sectionHeight.teaser}>
            <VisibilitySensor
              onChange={isVisible =>
                !this.state.heart &&
                this.setAnimationState('heart', isVisible ? 0 : undefined)
              }
            >
              <Sprite
                layout={heartLayout}
                name={'heart'}
                animationState={this.state.heart}
                setAnimationState={this.setAnimationState}
                isActive={true}
                isSticky={false}
                translateTop={-0.5}
                animationsDefinitions={heartStates}
              />
            </VisibilitySensor>
            <GridBox layout={teaser} style={{ alignContent: 'start' }}>
              <Teaser
                label={home.teaser.label}
                title={home.teaser.title}
                texts={home.teaser.texts}
                button={home.teaser.button}
                linkTo={getUrlFromId(locale, 'aboutUs')}
              />
            </GridBox>
            <VisibilitySensor
              onChange={isVisible =>
                isVisible &&
                !this.state.bolt &&
                this.setAnimationState('bolt', 0)
              }
            >
              <Sprite
                layout={boltLayout}
                name={'bolt'}
                animationState={this.state.bolt}
                setAnimationState={this.setAnimationState}
                translateTop={0.5}
                isActive={true}
                isSticky={false}
                animationsDefinitions={boltStates}
              />
            </VisibilitySensor>
            <GridBox layout={patternsLayout.p4} className={'showAbove-tablet1'}>
              <SimpleImage src={'/assets/patterns/home/pattern-02.svg'} />
            </GridBox>
          </GridSection>

          <ImageCarousel
            items={home.imagesCarousel}
            setAnimationState={this.setAnimationState}
            isMobile={!isScreenDesktop}
          />

          <GridSection gridRows={sectionHeight.team} dark={false}>
            <GridBox layout={team.header} style={{ alignContent: 'center' }}>
              <Heading1>{home.team.title}</Heading1>
              <Text.p>{home.team.subtitle}</Text.p>
            </GridBox>
          </GridSection>

          <Gallery
            layout={gallery}
            userLanguage={locale}
            darkTheme={this.state.darkTheme}
          />

          <GridSection gridRows={sectionHeight.jobs} dark={false}>
            <GridBox layout={jobs.teaser} style={{ alignContent: 'start' }}>
              <Teaser
                label={home.jobs.label}
                title={home.jobs.title}
                texts={home.jobs.texts}
                button={home.jobs.button}
                linkTo={getUrlFromId(locale, 'jobs')}
                column={true}
                darkTheme={this.state.darkTheme}
              />
            </GridBox>
            <GridBox
              layout={patternsLayout.p5}
              style={{ width: '157%' }}
              className={'showAbove-mobile2'}
            >
              <SimpleImage src={'/assets/patterns/home/pattern-09.svg'} />
            </GridBox>
            <GridBox className={'showAbove-mobile2'} layout={patternsLayout.p6}>
              <SimpleImage src={'/assets/patterns/home/pattern-10.svg'} />
            </GridBox>
            <GridBox
              // className={'showAbove-mobile2'}
              layout={patternsLayout.p7}
            >
              <SimpleImage src={'/assets/patterns/home/pattern-11.svg'} />
            </GridBox>
            <GridBox
              layout={patternsLayout.p8}
              // className={'showAbove-mobile2'}
            >
              <SimpleImage src={'/assets/patterns/home/pattern-12.svg'} />
            </GridBox>
          </GridSection>
          <VisibilitySensor
            partialVisibility={true}
            minTopValue={150}
            onChange={isVisible => this.setState({ darkTheme: isVisible })}
          >
            <RandomProfile
              userLanguage={locale}
              darkTheme={this.state.darkTheme}
            />
          </VisibilitySensor>
        </main>
      </PageLayout>
    );
  }
}
export default Home;
