import React, { Component } from 'react';
import styled from 'styled-components';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

import { Heading2, Text } from '../../helper/Typography';
import { GridBox, GridSection, FullWidthElement } from '../Grid';
import { colors } from '../../helper/Variables';
import {
  sectionHeight,
  carouselItemLayout,
  carouselTextMobileLayout,
} from './layouts';
import { imagesPaths } from './imagesPaths';
import { CarouselControls } from './CarouselControls';
import { Image } from '../Image/Image';

export default class ImageCarousel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slideIndex: 0,
      showControls: false,
    };

    this.carouselItems =
      imagesPaths.length === props.items.length
        ? imagesPaths.map((image, idx) => ({
            label: props.items[idx].label,
            title: props.items[idx].title,
            text: props.items[idx].text,
            imageFolderUrl: image.imageFolderUrl,
          }))
        : [];
  }

  componentDidMount() {
    //workaround ssr
    this.setState({
      showControls: true,
    });
  }

  render() {
    return (
      <CarouselWrapper>
        {this.state.showControls && (
          <CarouselControls
            slides={this.carouselItems.length}
            prevDisabled={this.state.slideIndex === 0}
            nextDisabled={
              this.state.slideIndex === this.carouselItems.length - 1
            }
            onNextClick={() =>
              this.setState({
                slideIndex: this.state.slideIndex + 1,
              })
            }
            onPrevClick={() =>
              this.setState({
                slideIndex: this.state.slideIndex - 1,
              })
            }
            active={this.state.slideIndex}
            dark={false}
          />
        )}
        <Carousel
          onChange={slideIndex => {
            this.props.isMobile &&
              this.setState({
                slideIndex,
              });
            !this.props.isMobile && this.props.setAnimationState('bolt', 1);
          }}
          showThumbs={false}
          showStatus={false}
          showIndicators={false}
          selectedItem={this.state.slideIndex}
          swipeable
          emulateTouch
          showArrows={false}
          swipeScrollTolerance={3}
        >
          {this.carouselItems.map(carouselItem => (
            <div key={carouselItem.title}>
              <GridSection gridRows={sectionHeight.carouselItem}>
                <FullWidthElement>
                  <Image imageFolderUrl={carouselItem.imageFolderUrl} />
                </FullWidthElement>
                <GridBox
                  layout={carouselItemLayout}
                  style={{
                    alignContent: this.props.isMobile ? 'start' : 'end',
                  }}
                >
                  <Text.p style={{ color: colors.white, marginBottom: '20px' }}>
                    {carouselItem.label}
                  </Text.p>
                  <Heading2 style={{ color: colors.white }}>
                    {carouselItem.title}
                  </Heading2>
                  <Text.p
                    className={'showAbove-tablet1'}
                    style={{ color: colors.white }}
                  >
                    {carouselItem.text}
                  </Text.p>
                </GridBox>
              </GridSection>
              {this.props.isMobile && (
                <GridSection gridRows={sectionHeight.carouselItemMobile} dark>
                  <GridBox layout={carouselTextMobileLayout}>
                    <Text.p style={{ marginTop: '20px' }}>
                      {carouselItem.text}
                    </Text.p>
                  </GridBox>
                </GridSection>
              )}
            </div>
          ))}
        </Carousel>
      </CarouselWrapper>
    );
  }
}

export const CarouselWrapper = styled.div`
  position: relative;

  .carousel .slide {
    background: inherit;
  }

  div {
    outline: none;
    cursor: auto;
  }
  ul {
    padding: 0;
  }
`;

export const BackgroundImage = styled.div`
  background: url('${({ urlL }) => urlL}');
  background-position: center;
  background-size: cover;

  @media (max-width: 768px) {
    background: url('${({ urlS }) => urlS}');
    background-position: center;
    background-size: cover;
  }
`;
