import React from 'react';
import styled, { css } from 'styled-components';
import { colors } from '../../helper/Variables';

export const CarouselControls = ({
  slides,
  onPrevClick,
  onNextClick,
  prevDisabled,
  nextDisabled,
  active,
  dark,
}) => {
  let dots = [];
  for (let i = 0; i < slides; i++) {
    dots.push(<Dot key={`${i}-dot`} active={active === i} dark={dark} />);
  }

  return (
    <ControlsWrapper slides={slides}>
      <IconContainer
        className={'showAbove-tablet1'}
        left
        disabled={prevDisabled}
        onClick={prevDisabled ? null : onPrevClick}
        dark={dark}
      />
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          margin: '0 20px',
          width: 20 * dots.length + 'px',
        }}
      >
        {dots}
      </div>
      <IconContainer
        className={'showAbove-tablet1'}
        disabled={nextDisabled}
        onClick={nextDisabled ? null : onNextClick}
        dark={dark}
      />
    </ControlsWrapper>
  );
};

const ControlsWrapper = styled.div`
  position: absolute;
  z-index: 10;
  bottom: 66px;
  right: ${({ slides }) =>
    slides >= 4 ? 'calc(50vw - (250px / 2))' : 'calc(50vw - (200px / 2))'};
  width: ${({ slides }) => (slides >= 4 ? '250px' : '200px')};
  height: 28px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  @media (max-width: 768px) {
    bottom: 20px;
  }

  @media (max-width: 1024px) {
    bottom: 48px;
  }
`;

const Dot = styled.div`
  width: 8px;
  height: 8px;
  ${({ active, dark }) => {
    if (active) {
      return css`
        background: ${!dark ? colors.white : colors.black};
      `;
    } else {
      return css`
        background: ${colors.grey};
      `;
    }
  }};
  border-radius: 50%;
`;

const IconContainer = styled.div`
  cursor: ${({ disabled }) => (disabled ? 'auto' : 'pointer!important')};
  width: 28px;
  height: 28px;
  background:
  url('/assets/icons/${({ left }) => (left ? 'prev' : 'next')}-icon-${({
  dark,
}) => (dark ? 'dark' : 'light')}${({ disabled }) =>
  disabled && '-inactive'}.svg');
  background-position: center;
  background-size: cover;
  position: relative;
  transition: all 0.3s ease;

  ${({ disabled }) =>
    !disabled &&
    css`
  &:before {
    content: '';
    top: 0;
    left: 0;
    width: 28px;
    height: 28px;
    border-radius: 50%;
    position: absolute;
    background: url('/assets/icons/${({ left }) =>
      left ? 'prev' : 'next'}-icon-hover-${({ dark }) =>
      dark ? 'dark' : 'light'}.svg');
    background-position: center;
    background-size: cover;
    opacity: 0;
    transition: all 0.3s ease;
    z-index: 200;
  }
  &:hover {
    &:before {
      opacity: 1;
    }
  }
  &:active {
    transform: scale(0.9);
  }
  `}
`;
